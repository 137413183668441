import Link from 'next/link';
import { useEffect, useState } from 'react';
import styles from './index.module.less';
import { Button, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'next-i18next';
import { useUserProvider } from '@/context/UserProvider';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useRouter } from 'next/router';

const UserContent = () => {
  const { isLogin, onOpenLoginModal, onChangedLoginView, onSignOut } = useUserProvider();
  const [firstLetter, setFirstLetter] = useState<string>('');
  const { t } = useTranslation('common');
  const router = useRouter()

  const items: MenuProps['items'] = [
    {
      key: 'my-photo',
      label: <Link href={'/my-photo'}>{t('MyPhotoGallery')}</Link>,
    },
    {
      key: 'settings',
      label: <Link href={'/settings'}>{t('Settings')}</Link>,
    },
    {
      key: 'billing-records',
      label: <Link href={'/billing-records'}>{t('PaymentHistory')}</Link>,
    },
    {
      key: 'logout',
      label: (
        <Link href={'/'} onClick={() => onSignOut()}>
          {t('LogOut')}
        </Link>
      ),
    },
  ];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        if (user?.email?.length > 0) {
          const tempLetter = user?.email[0];
          setFirstLetter(tempLetter.toLocaleUpperCase());
        } else {
          setFirstLetter('');
        }
      } else {
        setFirstLetter(undefined);
      }
    });

    return () => {
      if (isLogin) {
        unsubscribe()
      } else {
        setFirstLetter(undefined);
      }
    };
  }, [isLogin]);

  return (
    <div className={styles.content}>
      {firstLetter != undefined ? (
        <Dropdown menu={{ items }} placement="bottomRight" autoAdjustOverflow={false}>
          <Button
            className={styles.emailIcon}
            onClick={() => { router.push('/my-photo') }}>
            {firstLetter}
          </Button>
        </Dropdown>
      ) : (
        <Button
          className={styles.login}
          onClick={() => {
            onOpenLoginModal(true);
            onChangedLoginView(true);
          }}
        >
          {`${t('SignIn_SignUp')}`}
        </Button>
      )}
    </div>
  );
};

export default UserContent;
