import { Drawer } from 'antd';
import { useToggle } from 'react-use';
import classNames from 'classnames';
import { useConfigProvider } from '@/context/ConfigProvider';
import styles from './index.module.less';
import { useEffect, type ReactNode } from 'react';
import { AppSettings } from '@/shared/app-common';
import { CloseOutlined } from '@ant-design/icons';
import NavItem from '../NavItem';
import { useTranslation } from 'next-i18next';
import { useUserProvider } from '@/context/UserProvider';

const NavHamburger = ({ children }: { children?: ReactNode[] }) => {
  const { isMobile } = useConfigProvider();
  const [open, toggle] = useToggle(false);
  const { t } = useTranslation('common')
  const { isLogin, onOpenLoginModal, onChangedLoginView, onSignOut } = useUserProvider()

  const dataList = (isLogin ? [{
    text: t('MyPhotoGallery'),
    path: '/my-photo',
  },
  {
    text: t('Settings'),
    path: '/settings',
  },
  {
    text: t('PaymentHistory'),
    path: '/billing-records'
  },
  {
    text: t('LogOut'),
  }
  ] : [{
    text: t('SignIn_SignUp'),
  }])

  return (
    <div
      className={classNames(styles.navHamburgerWarp, {
        [styles.showClose]: open,
      })}
      onClick={toggle}
    >
      {new Array(3).fill(1).map((_, index) => (
        <span key={`burger-${index}`} className={styles.hamburgerLine} />
      ))}
      <Drawer
        style={{ background: `var(--foreground-rgb)` }}
        title={
          <>
            <img
              src={AppSettings.logoPath}
              alt={AppSettings.name}
              style={{ height: '32px' }}
              loading="lazy"
            />
            <img
              src={AppSettings.namePath}
              alt={AppSettings.name}
              style={{ height: '32px', paddingLeft: '15px' }}
              loading="lazy"
            />
          </>
        }
        closeIcon={<CloseOutlined style={{ color: `var(--app-text-color)` }} />}
        closable
        width={isMobile ? '100%' : 400}
        placement="left"
        onClose={() => {
          toggle(true);
        }}
        // maskClosable={false}
        open={open}
        rootClassName={styles.leftMenuDrawerWarp}
        key={'left-menu-drawer'}
      >
        <div className={styles.mobileNavMenuWarp}>
          {children}
          {(dataList).map((item, index) => {
            return <div
              key={item?.text}
              onClick={(e) => {
                if (!(item?.path?.length > 0)) {
                  e?.preventDefault()
                  if (isLogin) {
                    onSignOut()
                  } else {
                    onChangedLoginView(true)
                    onOpenLoginModal(true)
                  }
                }
              }}>
              <NavItem item={item} />
            </div>
          })}
        </div>

      </Drawer>
    </div>
  );
};

export default NavHamburger;
