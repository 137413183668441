import Image from 'next/image';
import classNames from 'classnames';
import Link from 'next/link';
import styles from './index.module.less';
import RetroTitle from '@/components/Common/RetroTitle';
import { AppSettings, letterList, lowerName } from '@/shared/app-common';
import { useTranslation } from 'next-i18next';
import { CommonBaseContent } from '../CommonBackContent';
import { AllSupportLanguages } from '@/shared/language';
import FooterListContent from '@/components/Common/FooterListContent';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';

const GlobalFooter = ({ show = true, className }: { show?: boolean; className?: string }) => {
  const { t } = useTranslation('common');
  const router = useRouter();

  if (!show) {
    return <></>;
  }
  const cls = classNames(styles.globalFooterWarp, className);
  // const pathname = (typeof window !== 'undefined') ? window.location.pathname : "/"

  const productList = [
    {
      title: t('BabyNames'),
      link: `/baby-name-generator`,
    },
    {
      title: t('Blog'),
      link: `/blog`,
    },
    {
      title: t('GPTsForMoms'),
      link: `/gpts`,
    },
  ];

  const nameList = [
    {
      title: t('BoyNames'),
      link: `/baby-name-search?gender=male`,
    },
    {
      title: t('GirlNames'),
      link: `/baby-name-search?gender=female`,
    },
    {
      title: t('UnisexNames'),
      link: `/baby-name-search?gender=both`,
    },
  ];

  const hotYearNames = () => {
    let list: any[] = [];

    for (let index = 0; index < 8; index++) {
      const yearStr = `${dayjs().subtract(index, 'year').format('YYYY')}`;
      let item = {
        title: `${t('MostPopularBabyNames_x')}${yearStr}`,
        link: `/baby-name-search?year=${yearStr}`,
      };

      list.push(item);
    }
    return list;
  };

  const isEnCode = () => {
    return router.locale === undefined || router.locale === 'en';
  };

  return (
    <div className={cls}>
      <CommonBaseContent>
        <div className={styles.line} />
        <div className={styles.mainConetent}>
          <div className={styles.logConatiner}>
            <Link href="/">
              <RetroTitle
                text={
                  <div style={{ height: '32px' }}>
                    <Image
                      className={styles.logo}
                      src={AppSettings.logoPath}
                      alt={AppSettings.name}
                      loading="lazy"
                      width={32}
                      height={32}
                    />
                    <Image
                      src={AppSettings.namePath}
                      alt={AppSettings.name}
                      height={32}
                      width={100}
                      style={{
                        // height: '32px',
                        // marginLeft: '10px',
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                }
                fontSize={28}
                showAlert={true}
              />
            </Link>
            <p>{t(`${lowerName}_Title`)}</p>
          </div>
          <div className={styles.otherConatiner}>
            <div>
              <FooterListContent title={t('Lists')} listData={productList} />
              <div style={{ marginTop: '20px' }}>
                <FooterListContent
                  title={t('Info')}
                  listData={[
                    {
                      title: t('privacyPolicy'),
                      link: `/privacy-policy`,
                    },
                    {
                      title: t('termsOfUs'),
                      link: `/aboutus`,
                    },
                  ]}
                />
              </div>
            </div>

            <div>
              <FooterListContent title={t('Gender_SortedBabyNames')} listData={nameList} />

              {isEnCode() && (
                <div style={{ marginTop: isEnCode() ? '20px' : null }}>
                  <FooterListContent
                    title={t('BabyNames_A_Z')}
                    listData={letterList}
                    isInline={true}
                  />
                </div>
              )}
            </div>

            <FooterListContent
              title={t('TopBabyNames')}
              listData={hotYearNames()}
              isInline={true}
            />
          </div>
        </div>
        <div>
          <ul className={styles.languageContainer}>
            {AllSupportLanguages.map((item, index) => {
              return (
                <li key={index}>
                  <span
                    onClick={() => (window.location.href = `/${item?.code}${router.asPath}`)}
                    style={{
                      color:
                        item?.code === router.locale
                          ? `var(--app-primary-color)`
                          : `var(--app-text-color)`,
                    }}
                  >
                    {item?.name}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
        <div
          className={styles.copyrightContainer}
        >{`Copyright ©2024 ${AppSettings.name}. All Rights Reserved.`}</div>
      </CommonBaseContent>
    </div>
  );
};

export default GlobalFooter;
