import styles from './index.module.less';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { Dropdown } from 'antd';
import { ga_logEvent } from '@/shared/ga';
export interface MarketplaceNavItem {
  text: string;
  path?: string;
  enentName?: string;
  icon?: { left: string; right: string };
  dropdownItems?: MarketplaceNavItem[];
  onClick?: (item: MarketplaceNavItem) => void;
}
const NavItem = ({ item, className }: { item: MarketplaceNavItem; className?: string }) => {
  const router = useRouter();

  const getColor = (pathStr: string) => {
    return router.asPath === pathStr ? `var(--app-primary-color)` : `var(--app-text-color)`;
  };

  const handleItemClick = (e: any, item: MarketplaceNavItem) => {
    if (item?.enentName) {
      ga_logEvent(item?.enentName);
    }
    if (item?.onClick) {
      item.onClick(item);
      return;
    }
    router.push(item?.path || '/');
  };

  const renderItem = (item: MarketplaceNavItem, index?: string | number) => {
    return (
      <div
        className={classNames(styles.navItemWarp, className)}
        key={index}
        onClick={(e) => handleItemClick(e, item)}
      >
        {item.dropdownItems ? (
          <Dropdown
            menu={{
              items: item.dropdownItems.map((item, index) => {
                return {
                  key: index,
                  label: (
                    <span
                      style={{ color: getColor(item?.path) }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleItemClick(e, item);
                      }}
                    >
                      {item.text}
                    </span>
                  ),
                };
              }),
            }}
            placement="bottom"
            autoAdjustOverflow={false}
          >
            <span className={styles.navItemText} style={{ color: getColor(item?.path) }}>
              {item.text}
            </span>
          </Dropdown>
        ) : (
          <span className={styles.navItemText} style={{ color: getColor(item?.path) }}>
            {item.text}
          </span>
        )}
      </div>
    );
  };
  return renderItem(item, `nav-item`);
};
export default NavItem;
